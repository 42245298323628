import { useState, useContext } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../context/AppContext";
import Modal from "react-modal";
import { customStyles } from "../CustomStyle";

import { MDBBtn, MDBCardBody, MDBCardImage, MDBInput } from "mdb-react-ui-kit";
function Login() {
  const initialState = {
    email: "",
    password: "",
  };

  const { setIsMemberLoggedIn } = useContext(AppContext);
  const [forgotModal, setForgotModal] = useState(false);
  const [forgotP, setFCredentials] = useState({ femail: "" });

  const [inputData, setInputData] = useState(initialState);
  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const onChangeForgot = (e) => {
    setFCredentials({ ...forgotP, [e.target.name]: e.target.value });
  };

  const requestPassword = async (e) => {
    const response = await fetch(
      process.env.REACT_APP_BASEURL + "/forgotPassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: forgotP.femail,
        }),
      }
    );
    const data = await response.json();

    if (data.code === 200) {
      // Save the auth token and redirect
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setForgotModal(false);
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();
    const response = await fetch(process.env.REACT_APP_BASEURL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputData.email,
        password: inputData.password,
      }),
    });
    const data = await response.json();

    if (data.code === 200) {
      // Save the auth token and redirect
      localStorage.setItem("member-token", data.body?.authtoken);
      setIsMemberLoggedIn(true);
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      const path = "/member/home";

      setTimeout(function () {
        window.location.href = path;
      }, 600);
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className="login-body">
      <Card className="m-5">
        <Row>
          <Col md="8">
            <MDBCardImage
              src={process.env.REACT_APP_FRONTEND_URL + "/1.png"}
              alt="login form"
              className="rounded-start w-100"
              height={600}
            />
          </Col>

          <Col className="login-div" md="4">
            <form onSubmit={handleSubmit} noValidate>
              <MDBCardBody className="d-flex flex-column">
                <div className="d-flex flex-row ">
                  <img
                    src={process.env.REACT_APP_FRONTEND_URL + "/logo.png"}
                    alt="Logo"
                    style={{ color: "#ff6219", width: "30%" }}
                  />
                </div>

                <h5
                  className="fw-normal my-4 pb-3 mt-5"
                  style={{ letterSpacing: "1px" }}
                >
                  Sign into your account
                </h5>

                <MDBInput
                  wrapperClass="mb-4"
                  label="Email address"
                  id="formControlLg"
                  type="email"
                  maxLength={100}
                  size="lg"
                  name="email"
                  value={inputData.email}
                  onChange={onChange}
                  required
                />
                <MDBInput
                  wrapperClass="mb-4"
                  label="Password"
                  id="formControlLg"
                  type="password"
                  maxLength={20}
                  size="lg"
                  name="password"
                  value={inputData.password}
                  onChange={onChange}
                />

                <MDBBtn className="mb-4 px-5" color="primary" size="lg">
                  Login
                </MDBBtn>
                <a href="/#" onClick={() => setForgotModal(true)}>
                  Forgot Password?
                </a>
              </MDBCardBody>
            </form>
          </Col>
          <Modal
            isOpen={forgotModal}
            // onAfterOpen={afterOpenModal}
            style={customStyles}
            appElement={document.getElementById("root")}
            contentLabel="Example Modal"
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <button onClick={() => setForgotModal(false)}>close</button>

            <div className="mb-3 mt-4 ">
              <span className="fs-6">
                Please enter email address below , We will send you an email for
                further instructions.
              </span>

              <input
                required
                type="email"
                placeholder="abc@example.com"
                className="mt-2 form-control"
                value={forgotP.femail}
                onChange={onChangeForgot}
                id="Femail"
                name="femail"
                aria-describedby="emailHelp"
              />
            </div>

            <button onClick={requestPassword} className="btn btn-info">
              Request for a Password
            </button>
          </Modal>
        </Row>
      </Card>
    </div>
  );
}

export default Login;
