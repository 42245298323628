import swal from "sweetalert";

export const callAdminApi = (url, method, content) => {
  const headers = {
    "access-control-allow-origin": "*",
  };

  if (content.type === "json") {
    headers["Content-Type"] = "application/json";
  }

  headers.Authorization = localStorage.getItem("admin-token");

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method || "GET",
      body: content.body ? content.body : null,
      // mode: "cors",
      headers: headers,
    })
      .then(async (response) => {
        const dataP = await response.json();

        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          resolve(dataP);
        } else {
          //handle errors in the way you want to
          switch (response.status) {
            case 401:
              console.log("Unauthorized");
              swal("Unauthorized!", "You are not authorized", "error");
              break;

            case 403:
              swal("Oops!", dataP.message, "error");

              break;
            default:
              resolve(dataP);
              break;
          }

          //here you also can thorow custom error too
          reject(dataP);
        }
      })
      .catch((error) => {
        //it will be invoked mostly for network errors
        //do what ever you want to do with error here
        console.log(error);
        reject(error);
      });
  });
};

export const callMemberApi = (url, method, content) => {
  const headers = {
    "access-control-allow-origin": "*",
  };

  if (content.type === "json") {
    headers["Content-Type"] = "application/json";
  }

  headers.Authorization = localStorage.getItem("member-token");

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method || "GET",
      body: content.body ? content.body : null,
      // mode: "cors",
      headers: headers,
    })
      .then(async (response) => {
        const dataP = await response.json();

        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          resolve(dataP);
        } else {
          //handle errors in the way you want to
          switch (response.status) {
            case 401:
              console.log("Unauthorized");
              swal("Unauthorized!", "You are not authorized", "error");
              break;

            case 403:
              swal("Oops!", dataP.message, "error");

              break;
            default:
              resolve(dataP);
              break;
          }

          //here you also can thorow custom error too
          reject(dataP);
        }
      })
      .catch((error) => {
        //it will be invoked mostly for network errors
        //do what ever you want to do with error here
        console.log(error);
        reject(error);
      });
  });
};

