import { useLocation } from "react-router-dom";
import NavbarAdmin from "./NavbarAdmin";
import NavbarWeb from "../layout/website/web/NavbarWeb";
import NavbarMember from "./NavbarMember";
export default function NavbarTop() {
  let location = useLocation();

  const checkPathType = () => {
    const { pathname } = location;
    if (pathname.includes("/admin")) return "admin";
    if (pathname.includes("/member")) return "member";
    return "web";
  };

  const checkNavbar = () => {
    const pathType = checkPathType();
    if (pathType === "admin") return <NavbarAdmin />;
    if (pathType === "member") return <NavbarMember />;
    return <NavbarWeb />;
  };

  return checkNavbar();
}
