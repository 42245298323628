import React, { useState, useEffect, useCallback, useContext } from "react";
import { Row, Card, Col, Container, Button } from "react-bootstrap";
import Topsection from "../../headers/Topsection";
import { DateRangePicker } from "rsuite";
import TradingItem from "./TradingItem";
import { customStyles } from "../CustomStyle";
import { callMemberApi, callAdminApi } from "../../utils/ApiInterface";
import { toast } from "react-toastify";
import LoadingSpinner from "../../utils/Spinner";
import Pagination from "../Pagination";
import SendEquity from "./SendEquity";
import Modal from "react-modal";
import { AppContext } from "../../context/AppContext";
import { useSelector } from "react-redux";

export default function Trading() {
  const [rangeValue, setValue] = useState([]);
  const [type, setType] = useState();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);
  const [nPages, setNPages] = useState(1);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [allChannels, setAllChannels] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [balance, setBalance] = useState(0);

  const [payload, setPayload] = useState({
    page: 1,
    limit: recordsPerPage,
    type: "",
    range: "",
  });
  const profileInfo = useSelector((state) => state.memberloginInfo.data);

  const { isDataUpdated, setDataUpdated } = useContext(AppContext);
  useEffect(() => {
    fetchAllChannels();
    fetchAllMembers();
  }, []);

  const fetchAllMembers = useCallback(() => {
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/members/all", "POST", {
      type: "json",
      auth: true,
      body: JSON.stringify({ selectAll: true }),
    })
      .then(async (data) => {
        if (data.code === 200) {
          const members = data.body.rows;
          const array = [];

          members.forEach((item) => {
            if (item.member_id !== profileInfo.member_id) {
              array.push({
                ...item,
                label: item.name,
                value: item.member_id,
              });
            }
          });
          setAllMembers([...array]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchAllChannels = useCallback(() => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/channels/all",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({ selectAll: true }),
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          const members = data.body.rows;
          const array = [];

          members.map((item) => {
            return array.push({
              ...item,
              label: item.name,
              value: item.channel_id,
            });
          });
          setAllChannels([...array]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchApi = () => {
    setIsLoading(true);
    callMemberApi(process.env.REACT_APP_BASEURL + "/transactions/all", "POST", {
      type: "json",
      body: JSON.stringify(payload),
      auth: true,
    })
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          const allInfo = data.body.allInfo;
          const members = allInfo.rows;
          setBalance(data.body.balance);
          const items = [...members];

          setTransactions([...items]);
          const numberPages = Math.ceil(allInfo.count / recordsPerPage);
          setNPages(numberPages);
          setDataUpdated(false);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    fetchApi();
    setModalOpen(false);
  }, [payload, isDataUpdated]);

  useEffect(() => {
    setPayload({ ...payload, range: rangeValue, type, page: currentPage });
  }, [rangeValue, type, currentPage]);

  return (
    <>
      {" "}
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <Topsection
        custId={profileInfo?.member_code}
        title={`Transactions`}
      ></Topsection>{" "}
      <div style={{ backgroundColor: "#f6f6f6" }}>
        <Container>
          <Row bg="dark">
         
            

            <Col md="3" className="mt-3">
              <button className="fs-5 badge badge-info">
                Equity Balance : {balance} %
              </button>
            </Col>
            <Col className="mt-3" md="3 text-start">
              <button
                className="btn btn-info"
                onClick={(e) => setModalOpen(true)}
              >
                Send Equity
              </button>
            </Col>
          </Row>
          <Card className="p-3 mt-3">
            <Row className="mt-4">
              <Col md="3 text-start">
                <label> Transactions</label>

                <select
                  onChange={(e) => setType(e.target.value)}
                  className="form-select mt-2 p-2"
                  aria-label=".form-select-lg example"
                >
                  <option value="">Select Transaction type</option>
                  <option value="1">Sent</option>
                  <option value="2">Received</option>
                </select>
              </Col>

              <Col className="mt-1" md="3 text-start">
                <label> Date range</label>

                <DateRangePicker
                  onChange={setValue}
                  placeholder="Select date range"
                  className="p-1 fs-4"
                  editable={false}
                  format="MM/dd/yyyy hh:mm aa"
                  showMeridian
                />
              </Col>

              <Col className="mt-1" md="2">
                <Button className="form-control text-success  fs-5 btn btn-light border border-success mt-4 p-2">
                  Apply
                </Button>
              </Col>

              <Row className="mt-5 text-start">
                <span>
                  Brokerage Update Disclosure{" "}
                  <i className="fa fa-thin fa-circle-info"></i>
                </span>
              </Row>
            </Row>

            <Row className="mt-5">
              <TradingItem items={transactions}></TradingItem>
            </Row>
          </Card>
        </Container>

        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          appElement={document.getElementById("root")}
          contentLabel="Add Member"
        >
          <button onClick={(e) => setModalOpen(false)}>Close</button>

          <SendEquity
            allMembers={allMembers}
            allChannels={allChannels}
            action="add"
          ></SendEquity>
        </Modal>
      </div>
    </>
  );
}
