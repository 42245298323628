import { Link } from "react-router-dom";

export default function NavbarWeb() {
  return (
    <>
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <a href="/" className=" d-flex align-items-center">
            <img height="60" width="160" src="file2.png"></img>
          </a>

          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <a href="/#hero" className="active">
                  Home
                </a>
              </li>
              <li>
                <a href="/#about">What We Do</a>
              </li>
              <li>
                <a href="/#partners">Partners</a>
              </li>
              <li>
                <a href="/#contact">Contact Us</a>
              </li>
              <li>
                <a href="/login">Members Only</a>
              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </header>
    </>
  );
}
