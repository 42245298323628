import React from "react";
import "./App.css";
import { Alert } from "./components/utils/Alert.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/headers/NavbarTop.js";
import { AppContextProvider } from "./components/context/AppContext.js";
import Login from "./components/layout/website/Login.js";
import Home from "./components/layout/website/Home.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Portfolio from "./components/layout/website/Portfolio.js";
import Performance from "./components/layout/website/Performance.js";
import Trading from "./components/layout/website/Trading.js";
import AdminLogin from "./components/layout/admins/AdminLogin.js";
import AdminHome from "./components/layout/admins/AdminHome.js";
import ResetPassword from "./components/layout/admins/ResetPassword.js";
import Members from "./components/layout/admins/members/Members.js";
import Channels from "./components/layout/admins/channels/Channels.js";
import Earnings from "./components/layout/admins/earnings/Earnings.js";
import WebHome from "./components/layout/website/web/WebHome.js";
import ContactUs from "./components/layout/admins/contactus/ContactUs.js";
import MemberResetPassword from "./components/layout/website/MemberResetPassword.js"
function App() {
  // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="App">
      <AppContextProvider>
        <Router>
          <Alert></Alert>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<WebHome />} />
            <Route path="/login" index element={<Login />}></Route>
            <Route exact path="/member/home" name="Home" element={<Home />} />
            <Route
              exact
              path="/member/portfolio"
              name="Portfolio"
              element={<Portfolio />}
            />
            <Route
              exact
              path="/member/transactions"
              name="Trading"
              element={<Trading />}
            />
            <Route
              exact
              path="/member/performance"
              name="Performance"
              element={<Performance />}
            />

<Route
              exact
              path="/member/resetPassword"
              element={<MemberResetPassword />}
            />
            {/* <Route exact path="/admin/home" element={<AdminHome />} /> */}
            <Route exact path="/admin/login" element={<AdminLogin />} />
            <Route exact path="/admin/home" element={<AdminHome />} />
            <Route exact path="/admin/members" element={<Members />} />
            <Route exact path="/admin/channels" element={<Channels />} />
            <Route exact path="/admin/earnings" element={<Earnings />} />
            <Route exact path="/admin/contactus" element={<ContactUs />} />

            <Route
              exact
              path="/admin/resetPassword"
              element={<ResetPassword />}
            />
          </Routes>

        </Router>
      </AppContextProvider>
    </div>
  );
}

export default App;
