import React, { useEffect, useState, useCallback } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { Row, Col } from "react-bootstrap";
import Topsection from "../../headers/Topsection";
import { callAdminApi } from "../../utils/ApiInterface";
import LoadingSpinner from "../../utils/Spinner";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import AccountSummary from "../website/AccountSummary";
import { Container, Card } from "react-bootstrap";
import VirtualizedSelect from "react-virtualized-select";

const { afterToday } = DateRangePicker;

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

export default function AdminHome() {
  const [isLoading, setIsLoading] = useState(false);
  const [filterName, setFilterName] = useState("DAY");
  const [data, setData] = useState({});
  const [options, setoptions] = useState({});
  const [rangeValue, setValue] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [allMembers, setAllMembers] = useState([]);
  const [viewPersonal, setViewPersonal] = useState(false);

  const [payload, setPayload] = useState({
    filterName: filterName,
    range: "",
  });
  const generateDataPoints = (array) => {



    var dps = [];
    for (var i = 0; i < array.length; i++) {
      dps.push({
        y: Number(array[i].totalSum),
        label: array[i].memberInfo?.name,
      });
    }
    return dps;
  };

  useEffect(() => {
    fetchAllMembers();
  }, []);

  const fetchAllMembers = useCallback(() => {
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/members/all", "POST", {
      type: "json",
      auth: true,
      body: JSON.stringify({ selectAll: true }),
    })
      .then(async (data) => {
        if (data.code === 200) {
          const members = data.body.rows;
          const array = [];

          members.forEach((item) => {
            array.push({
              ...item,
              label: item.name,
              value: item.member_id,
            });
          });
          setAllMembers([...array]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    callDashboardApi();
  }, [payload]);

  useEffect(() => {
    if (memberId) setViewPersonal(true);
    else setViewPersonal(false);
  }, [memberId]);
  useEffect(() => {
    setPayload({
      ...payload,
      range: rangeValue,
      filterName,
      member_id: memberId,
    });
  }, [rangeValue, filterName, memberId]);

  useEffect(() => {
    if (data && data.all_earnings) {
      const earnings = data.all_earnings;

      const options = {
        animationEnabled: true,
        theme: "light",
        
        axisY: {
        title: "Active Earnings ($)",
          scaleBreaks: {
            autoCalculate: true,
            type: "wavy",
          }
        },
        data: [{
          type: "column",
          indexLabel: "{y}",		
          dataPoints: generateDataPoints(earnings),

         
        }]

      }
      



      setoptions(options);
    }
  }, [data]);

  const callDashboardApi = useCallback(() => {
    setIsLoading(true);
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/dashboard", "POST", {
      type: "json",
      body: JSON.stringify(payload),
      auth: true,
    })
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          setData({ ...data.body });
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [payload]);

  useEffect(() => {
    callDashboardApi();
  }, []);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>
      <Topsection
        custId={data?.profile_info?.code}
        title={`Welcome, ${data?.profile_info?.firstName || "Admin"}`}
      ></Topsection>
      <div className="container container-table">
        <Row className="mt-3">
          <Col>
            <Card className="bg-warning text-white bg-opacity-75 fs-5">
              <Card.Body>
                <Card.Title className="text-success"></Card.Title>
                <Card.Text>
                  <Container className="mt-4">
                    <Row>
                      <Col  md="1">
                        <i className="fa-solid fa-user fs-4"></i>
                      </Col>
                      <Col>
                        {" "}
                        <b>Total Members</b>
                      </Col>
                      <Col><h3>{data.totalMembers || 0}</h3></Col>
                    </Row>
                  </Container>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="bg-success text-white bg-opacity-75 fs-5">
              <Card.Body>
                <Card.Title className="text-success"></Card.Title>
                <Card.Text>
                  <Container className="mt-4">
                    <Row>
                      <Col md="1">
                        <i className="fa-solid fa-chart-simple fs-4"></i>{" "}
                      </Col>
                      <Col>
                        <b>Total Channels</b>
                      </Col>
                      <Col><h3>{data.totalChannels || 0}</h3></Col>
                    </Row>
                  </Container>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="bg-info text-white fs-5 bg-opacity-75">
              <Card.Body>
                <Card.Title className="text-success"></Card.Title>
                <Card.Text>
                  <Container className="mt-4">
                    <Row>
                      <Col  md="1">
                        <i className="fa-solid fa-message fs-4"></i>{" "}
                      </Col>
                      <Col>
                        {" "}
                        <b>Total Messages</b>
                      </Col>
                      <Col><h3>{data.totalmessages || 0}</h3></Col>
                    </Row>
                  </Container>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5 text-start">
          <label className="fs-5">Select member to view Summary</label>
          <VirtualizedSelect
            onChange={(value) => setMemberId(value?.member_id)}
            className="mt-1"
            aria-label=".form-select-lg example"
            value={memberId}
            options={allMembers}
          ></VirtualizedSelect>
        </Row>
        <AccountSummary
          className="mt-2"
          viewPersonal={viewPersonal}
          data={data}
        ></AccountSummary>

        {/* Graph Container */}

        <Row className="mt-5">
          <Col></Col>
          <Col></Col>

          <Col>
            <DateRangePicker
              onChange={setValue}
              placeholder="Select date range"
              className="p-2"
              editable={false}
              format="MM/dd/yyyy hh:mm aa"
              showMeridian
              shouldDisableDate={afterToday()}
            />
          </Col>
          
        </Row>
        <Row className="mt-3">
          <Col>
            <CanvasJSChart
              options={options}
              /* onRef={ref => this.chart = ref} */
            />
            {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
          </Col>
        </Row>
      </div>
    </>
  );
}
