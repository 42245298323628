import MemberItem from "./MemberItem";
import React, { useEffect, useState, useContext } from "react";
import Pagination from "../../Pagination";
import { AppContext } from "../../../context/AppContext";
import { callAdminApi } from "../../../utils/ApiInterface";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../utils/Spinner";
import Modal from "react-modal";
import AddEditMember from "./AddEditMember";
import { customStyles } from "../../CustomStyle";
export default function Members() {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);
  const [nPages, setNPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [equityType, setEquityType] = useState("");

  const [payload, setPayload] = useState({
    search: "",
    page: 1,
    limit: recordsPerPage,
    equity_type: "",
  });
  const { isDataUpdated, isDataDeleted, setDataDeleted, setDataUpdated } =
    useContext(AppContext);

  const fetchApi = () => {
    setIsLoading(true);
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/members/all", "POST", {
      type: "json",
      body: JSON.stringify(payload),
      auth: true,
    })
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          const resp = data.body;
          setContacts([...resp.rows]);
          const numberPages = Math.ceil(resp.count / recordsPerPage);
          setNPages(numberPages);
          setTotalRecords(resp.count);
          setDataDeleted(false);
          setDataUpdated(false);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setPayloadObject = () => {
    payload.equity_type = equityType;
    payload.search = search;
    payload.page = currentPage;
    payload.limit = recordsPerPage;
    setPayload({ ...payload });
  };
  useEffect(() => {
    setPayloadObject();
    setCurrentPage(currentPage);
    setModalOpen(false);
    fetchApi();
  }, [isDataDeleted, isDataUpdated, currentPage, equityType]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPayloadObject();
      setCurrentPage(1);
      fetchApi();
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="col-md-11 border row mx-2  my-1">
        <b className="fs-3 m-2 text-start">Members Management</b>

        <div className="col-md-3 mx-2">
          <select
            onChange={(e) => setEquityType(e.target.value)}
            className="form-select mt-2 p-2"
            aria-label=".form-select-lg example"
          >
            <option value="">Select Member type</option>
            <option value="0">Non Founder</option>
            <option value="1">Founder</option>
          </select>
        </div>

        <div className="col-md-5">
          <input
            type="text"
            placeholder="Search by (name,contact,email,pharmacy-name,pharmacy-address)"
            value={search}
            className="form-control mt-2 p-2"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="col-md-2">
          <button
            className="btn btn-lg btn-success mt-2"
            onClick={(e) => setModalOpen(true)}
          >
            <i className="fa fa-plus"></i>&nbsp;&nbsp;Add Member
          </button>
        </div>

        <div className="col-md-12 m-3">
          <div>
            <p
              style={{ float: "left", fontSize: "20px" }}
              className="float-left"
            >
              <b>Total Count: {totalRecords}</b>
            </p>
          </div>

          <MemberItem contacts={contacts} />
        </div>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Add Member"
      >
        <button onClick={(e) => setModalOpen(false)}>Close</button>

        <AddEditMember action="add"></AddEditMember>
      </Modal>
    </>
  );
}
