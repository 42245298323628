import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { callMemberApi } from "../utils/ApiInterface";
import { updateMemberLoginInfo } from "../../store/slices/MemberLoginInfoSlice";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function NavbarMember() {
  let location = useLocation();
  const profileInfo = useSelector((state) => state.memberloginInfo.data);
  const dispatch = useDispatch();

  const fetchMemberInfo = () => {
    callMemberApi(process.env.REACT_APP_BASEURL + "/fetchMemberInfo", "GET", {
      type: "json",
      auth: true,

      // body:JSON.stringify({leads:selctedIds})
    })
      .then(async (data) => {
        if (data.code === 200) {
          const result = data.body;
          dispatch(updateMemberLoginInfo(result));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (
      location.pathname.includes("member") &&
      !location.pathname.includes("reset")
    ) {
    const memberLoggedIn = isMemberLoggedIn();
    if (!memberLoggedIn) {
      window.location.href = "/login";
    } else {
      if (!(profileInfo && profileInfo.name)) {
        fetchMemberInfo();
      }
    }
  }
  }, [location.pathname]);


  const isMemberLoggedIn = () => {
    const tokenData = localStorage.getItem("member-token");
    if (!(tokenData && tokenData !== "null" && tokenData !== "undefined"))
      return false;
    else return true;
  };

  const handleLogout = () => {
    localStorage.setItem("member-token", null);
    // setIsLoggedIn(false);
    setTimeout(function () {
      window.location.href = process.env.REACT_APP_FRONTEND_URL + "/login";
    }, 2500);
  };

 

  return (
    <>
      <Navbar
        sticky="top"
        variant="light"
        collapseOnSelect
        expand="lg"
        style={{ backgroundColor: "#215f9a" }}
      >
        <Container>
          <img
            src={process.env.REACT_APP_FRONTEND_URL + "/logo_short.png"}
            alt="African Bank Network Logo"
            width={50}
            className="logo"
          />

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                className={
                  location.pathname.includes("/member/home")
                    ? "bg-svg-down active"
                    : "bg-svg-down"
                }
                as={Link}
                to="/member/home"
              >
                Account Summary
              </Nav.Link>
              <Nav.Link
                className={
                  location.pathname.includes("/member/portfolio") ? "active" : ""
                }
                as={Link}
                to="/member/portfolio"
              >
                Portfolios
              </Nav.Link>
              <Nav.Link
                className={
                  location.pathname.includes("/member/performance") ? "active" : ""
                }
                as={Link}
                to="/member/performance"
              >
                Historical Performance
              </Nav.Link>
              <Nav.Link
                className={
                  location.pathname.includes("/member/transactions") ? "active" : ""
                }
                as={Link}
                to="/member/transactions"
              >
                Trading
              </Nav.Link>
            </Nav>
            <Nav
              className="mr-4"
              style={{
                display: !isMemberLoggedIn() ? "none" : "",
                marginLeft: "20%",
              }}
            >
              <NavDropdown
                title={profileInfo.name || ""}
                className="fs-5 d-flex text-white float-right"
                id="basic-nav-dropdown"
              >
                <Card
                  bg="light"
                  text="dark"
                  style={{ width: "20rem" }}
                  className="mb-2"
                >
                  <Card.Header
                    style={{ display: !profileInfo.email ? "none" : "" }}
                    className="text-start"
                  >
                    <i className="fa fa-envelope fs-5"></i>
                    <span className="mx-2 fs-6">{profileInfo.email}</span>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text
                      style={{ display: !profileInfo.contact ? "none" : "" }}
                    >
                      <i className="fa fa-mobile fs-5"></i>{" "}
                      <span className="mx-1 fs-6">{profileInfo.contact}</span>{" "}
                    </Card.Text>
                    <button className="btn-get-danger" onClick={handleLogout}>
                      <span className="fs-5">Logout</span>
                    </button>
                  </Card.Body>
                </Card>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <br />
    </>
  );
}
