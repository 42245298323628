import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { toast } from "react-toastify";
import { callAdminApi } from "../../../utils/ApiInterface";

import {
  MDBBtn,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBRadio
} from "mdb-react-ui-kit";

export default function AddEditMember({ action, userDetail }) {
  const initialState = {
    name: (userDetail && userDetail.name) || "",
    email: (userDetail && userDetail.email) || "",
    contact: (userDetail && userDetail.contact) || "",
    pharmacy_name: (userDetail && userDetail.pharmacy_name) || "",
    pharmacy_address: (userDetail && userDetail.pharmacy_address) || "",
    founder_equity: (userDetail && userDetail.founder_equity) || "",
    id: (userDetail && userDetail.member_id) || "0",
    is_founder: (userDetail && userDetail.is_founder.toString()) || 0,
  };

  const { setDataUpdated } = useContext(AppContext);
  const [inputData, setInputData] = useState(initialState);

  const handleSubmit = async (e) => {
    if (!e.target.checkValidity()) {
      e.target.reportValidity();
      e.target.className += " was-validated";
      e.preventDefault();
      return;
    }
    e.preventDefault();

    callAdminApi(
      process.env.REACT_APP_BASEURL +
        `/admin/members/${
          action === "update" ? "update-member" : "create-member"
        }`,
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify(inputData),
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDataUpdated(true);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <div
      id="customers"
      className="infowindow overflow-auto"
    >
      <form
        className="needs-validation"
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
      >
        <MDBCardBody className="text-black d-flex flex-column justify-content-center">
          <h3 className="mb-5  mt-3 text-uppercase fw-bold">
            Enter Details to {action === "update" ? "Update" : "Add"} Member
          </h3>

          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Name"
                size="lg"
                name="name"
                maxLength={100}
                required={true}
                value={inputData.name}
                onChange={onChange}
                type="text"
              />
            </MDBCol>

            <MDBCol md="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Email"
                size="lg"
                name="email"
                type="email"
                required={true}
                maxLength={40}
                value={inputData.email}
                onChange={onChange}
                autoComplete="off"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Pharmacy Name"
                size="lg"
                name="pharmacy_name"
                maxLength={100}
                required={true}
                value={inputData.pharmacy_name}
                onChange={onChange}
                type="text"
              />
            </MDBCol>

            <MDBCol md="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Pharmacy Address"
                size="lg"
                name="pharmacy_address"
                type="text"
                required={true}
                maxLength={200}
                value={inputData.pharmacy_address}
                onChange={onChange}
                autoComplete="off"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Contact"
                size="lg"
                name="contact"
                maxLength={12}
                required={true}
                value={inputData.contact}
                onChange={onChange}
                type="tel"
              />
            </MDBCol>

            <MDBCol md="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Founder Equity"
                size="lg"
                name="founder_equity"
                type="number"
                required={true}
                maxLength={3}
                value={inputData.founder_equity}
                onChange={onChange}
                autoComplete="off"
                min={0}
                max={99}
              />
            </MDBCol>
          </MDBRow>


          <MDBRow>
          <label className="m-2">Select Member Type</label>
          <MDBRadio
                className="mx-2"
                name="is_founder"
                id="yes"
                value="1"
                label="Founder"
                checked={inputData.is_founder==="1"?"checked":""}
                onChange={onChange}
                inline
              />
              <MDBRadio
                className="mx-2"
                name="is_founder"
                id="no"
                value="0"
                checked={inputData.is_founder==="0"?"checked":""}
                onChange={onChange}
                label="Non Founder"
                inline
              />
          </MDBRow>


          <div className="text-center  pt-3">
            <MDBBtn className="ms-1 mb-2 row" color="warning" size="m">
              Submit
            </MDBBtn>
          </div>
        </MDBCardBody>
      </form>
    </div>
  );
}
