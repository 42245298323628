import React, { useEffect, useState, useContext, useCallback } from "react";
import Pagination from "../../Pagination";
import { AppContext } from "../../../context/AppContext";
import { callAdminApi } from "../../../utils/ApiInterface";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../utils/Spinner";
import Modal from "react-modal";
import AddEditEarning from "./AddEditEarning";
import { customStyles } from "../../CustomStyle";
import Earningitem from "./EarningItem";
import { DateRangePicker } from "rsuite";
import Swal from "sweetalert2";

export default function Earnings() {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);
  const [nPages, setNPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalCurrentAsset, setTotalCurrentAsset] = useState(0);
  const [filterMember, setFilterMember] = useState({});
  const [hTotalEarnings, setHtotalEarnings] = useState(0);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [equityType, setEquityType] = useState("");
  const [rangeValue, setValue] = useState([]);
  const [allChannels, setAllChannels] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [channelId, setChannelId] = useState("");
  const [importChannelId, setImportChannelId] = useState("");

  const [formData, setFormData] = useState(null);

  const [payload, setPayload] = useState({
    search: "",
    page: 1,
    limit: recordsPerPage,
    equity_type: "",
    range: "",
  });
  const { isDataUpdated, isDataDeleted, setDataDeleted, setDataUpdated } =
    useContext(AppContext);

  const excelDownloadUrl = () => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/transactions/export",
      "POST",
      {
        type: "json",
        auth: true,
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          const link = document.createElement("a");
          const path = data.body.path;
          link.href = `${process.env.REACT_APP_BACKEND_URL}/${path}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          setTimeout(() => {
            toast.success("Please check downloads directory", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1500);
        }
      })
      .catch((err) => console.log(err));
  };
  const fetchApi = () => {
    setIsLoading(true);
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/earnings/all",
      "POST",
      {
        type: "json",
        body: JSON.stringify(payload),
        auth: true,
      }
    )
      .then(async (data) => {
        setIsLoading(false);
        if (data.code === 200) {
          const resp = data.body;

          const items = [...resp.rows];

          const totalH = items.reduce(
            (acc, item) =>
              item.status === 1 ? acc + Number(item.amount) : acc + 0,
            0
          );

          setHtotalEarnings(totalH);
          setContacts([...resp.rows]);
          const numberPages = Math.ceil(resp.count / recordsPerPage);
          setNPages(numberPages);
          setTotalRecords(resp.count);
          setTotalCurrentAsset(resp.totalAssets);
          setFilterMember(resp.memberInfo);

          setDataDeleted(false);
          setDataUpdated(false);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  useEffect(() => {
    fetchAllChannels();
    fetchAllMembers();
  }, []);

  const fetchAllMembers = useCallback(() => {
    callAdminApi(process.env.REACT_APP_BASEURL + "/admin/members/all", "POST", {
      type: "json",
      auth: true,
      body: JSON.stringify({ selectAll: true }),
    })
      .then(async (data) => {
        if (data.code === 200) {
          const members = data.body.rows;
          const array = [];

          members.map((item) => {
            return array.push({
              ...item,
              label: item.name,
              value: item.member_id,
            });
          });
          setAllMembers([...array]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchAllChannels = useCallback(() => {
    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/channels/all",
      "POST",
      {
        type: "json",
        auth: true,
        body: JSON.stringify({ selectAll: true }),
      }
    )
      .then(async (data) => {
        if (data.code === 200) {
          const members = data.body.rows;
          const array = [];

          members.map((item) => {
            return array.push({
              ...item,
              label: item.name,
              value: item.channel_id,
            });
          });
          setAllChannels([...array]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const setPayloadObject = () => {
    payload.equity_type = equityType;
    payload.page = currentPage;
    payload.limit = recordsPerPage;
    payload.range = rangeValue;
    payload.member_id = memberId;
    payload.channel_id = channelId;
    setPayload({ ...payload });
  };

  useEffect(() => {
    setPayloadObject();
    setCurrentPage(currentPage);
    setModalOpen(false);
    fetchApi();
  }, [
    isDataDeleted,
    isDataUpdated,
    currentPage,
    equityType,
    rangeValue,
    channelId,
    memberId,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPayloadObject();
      setCurrentPage(1);
      fetchApi();
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    const nweForm = formData ? formData : new FormData();
    nweForm.append("channelId", importChannelId);
    setFormData(nweForm);
  }, [importChannelId]);

  const handleFileUpload = (event) => {
    // get the selected file from the input
    const file = event.target.files[0];
    // create a new FormData object and append the file to it

    const formData = new FormData();
    formData.append("importFile", file);
    formData.append("channelId", importChannelId);
    setFormData(formData);
  };

  const importData = () => {
    if (importChannelId === "") {
      return toast.error("Select Channel", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setIsLoading(true);

    callAdminApi(
      process.env.REACT_APP_BASEURL + "/admin/transactions/import",
      "POST",
      {
        type: "",
        body: formData,
        auth: true,
      }
    )
      .then(async (data) => {
        setIsLoading(false);

        if (data.code === 200) {
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Download Status",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "File downloaded, Please check downloads directory",
                  showConfirmButton: true,
                  confirmButtonText: `
                  <i class="fa fa-thumbs-up"></i> Great!
                `,
                });
                const link = document.createElement("a");
                link.href = `${process.env.REACT_APP_BACKEND_URL}/docs/Download-Status-Data.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }, 4000);
            }
          });

          setFormData(null);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);

        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
      </div>

      <div className="col-md-11 border row mx-2  my-1">
        <b className="fs-3 m-2 text-start">Earnings Management</b>

        <div className="col-md-3">
          <select
            onChange={(e) => setMemberId(e.target.value)}
            className="form-select mt-1 p-2"
            aria-label=".form-select-lg example"
          >
            <option value="">Select Member</option>
            {allMembers.map((item) => (
              <option key={item.id} value={item.member_id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3">
          <select
            onChange={(e) => setChannelId(e.target.value)}
            className="form-select mt-1 p-2"
            aria-label=".form-select-lg example"
          >
            <option value="">Select Channel</option>
            {allChannels.map((item) => (
              <option key={item.id} value={item.channel_id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3">
          <DateRangePicker
            onChange={setValue}
            placeholder="Select date range"
            className="form-control  p-2"
            editable={false}
            format="MM/dd/yyyy hh:mm aa"
            showMeridian
          />
        </div>

        <div className="col-md-2">
          <button
            className="btn btn-lg btn-success mt-2"
            onClick={(e) => setModalOpen(true)}
          >
            <i className="fa fa-plus"></i>&nbsp;&nbsp;Add Earning
          </button>
        </div>

          <div className="col-md-3 my-4  float-right border p-3">
            <div className="col-md-11 mt-1">
              <select
                onChange={(e) => setImportChannelId(e.target.value)}
                className="form-select p-2"
                aria-label=".form-select-lg example"
              >
                <option value="">Select Channel</option>
                {allChannels.map((item) => (
                  <option key={item.id} value={item.channel_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-11 mt-3">
              <input
                onChange={handleFileUpload}
                accept=".xlsx, .xls, .csv"
                className="mb-1  form-control"
                type="file"
              />
            </div>

            <div className="col-md-8 mt-3">
              <button
                disabled={isLoading}
                onClick={importData}
                className="btn btn-success m-0 btn-lg"
              >
                Import Data
              </button>
            </div>
          </div>

        <div className="col-md-12 mt-4">
          <p
            style={{ float: "left", fontSize: "22px" }}
            className="text-success float-left"
          >
            <b>Total Asset Value (Current): $ {totalCurrentAsset}</b>
          </p>

          <p className="text-warning fs-5">
            <b>
              Member (Equity):{" "}
              {filterMember && filterMember.founder_equity
                ? parseFloat(filterMember.founder_equity)
                : "NA"}{" "}
              %
            </b>
          </p>
        </div>
        <div className="col-md-12 m-3">
          <div>
            <p
              style={{ float: "left", fontSize: "20px" }}
              className="float-left"
            >
              <b>Total Count: {totalRecords}</b>
              <b className="mx-5">
                Historical Total Earnings: $ {hTotalEarnings}
              </b>

              <b className="mx-5">
                % Proportion of total group earnings:{" "}
                {!hTotalEarnings
                  ? 0
                  : ((hTotalEarnings / totalCurrentAsset) * 100).toFixed(
                      1
                    )}{" "}
                %
              </b>

              <b className="mx-5">
                Total Equity:{" "}
                {!hTotalEarnings
                  ? 0
                  : (
                      (filterMember && filterMember.founder_equity
                        ? parseFloat(filterMember.founder_equity)
                        : 0) +
                      (hTotalEarnings / totalCurrentAsset) * 100
                    ).toFixed(1)}{" "}
                %
              </b>
            </p>
          </div>

          <Earningitem contacts={contacts} />
        </div>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        appElement={document.getElementById("root")}
        contentLabel="Add Member"
      >
        <button onClick={(e) => setModalOpen(false)}>Close</button>

        <AddEditEarning
          allMembers={allMembers}
          allChannels={allChannels}
          action="add"
        ></AddEditEarning>
      </Modal>
    </>
  );
}
