export default function ContactItem({ contacts }) {
  return (
    <>
     
      <div
        id="customers"
        className="infowindow overflow-auto"
        style={{ height: "500px" }}
      >
        <table>
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((user, index) => (
              <tr key={"_" + index + "_" + user.member_id}>
                <td>{index + 1}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.subject}</td>
                <td>{user.message}</td>
                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>

        
      </div>
    </>
  );
}
