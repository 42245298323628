export const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 100,
  },
  content: {
    top: "55%",
    left: "40%",
    right: "auto",
    bottom: "auto",
    marginRight: "-10%",
    maxHeight: "700px",
    transform: "translate(-50%, -50%)",
  },
};
