import { createContext, useEffect, useState } from "react";

export const AppContext = createContext("");
export const AppContextProvider = ({ children }) => {
  const [leadsUpdate] = useState(false);
  const [isAdminLoggedIn, setAdminIsLoggedIn] = useState(false);
  const [isMemberLoggedIn,setIsMemberLoggedIn] = useState(false);
  const [isDataDeleted, setDataDeleted] = useState(false);
  const [isDataUpdated, setDataUpdated] = useState(false);

 

  const InitialContext = {
    leadsUpdate,
    isAdminLoggedIn,
    setAdminIsLoggedIn,
    isDataDeleted,
    setDataDeleted,
    isDataUpdated,
    setDataUpdated,
    setIsMemberLoggedIn,
    isMemberLoggedIn
  };

  return (
    <>
      <AppContext.Provider value={InitialContext}>
        {children}
      </AppContext.Provider>
    </>
  );
};
